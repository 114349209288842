'use client'

import { Label } from '@/components/ui/Label'
import { Input } from '@/components/ui/Input'
import { useEffect, useRef } from 'react'
import { Button } from './Button'
import { XIcon } from 'lucide-react'

export type FileInputProps = {
  label?: string
  disabled?: boolean
  accept?: string
} & (
  | {
      multiple: true
      value?: File[] | null
      onChange: (files: File[] | null) => void
    }
  | {
      multiple?: false
      value?: File | null
      onChange: (file: File | null) => void
    }
)

export default function FileInput({ label, disabled, multiple, onChange, accept, value }: FileInputProps) {
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (!files) {
      onChange(null)
      return
    }

    if (multiple) {
      onChange(Array.from(files))
    } else {
      onChange(files[0])
    }
  }

  const hasValue = Array.isArray(value) ? value.length > 0 : !!value

  useEffect(() => {
    if (ref.current) {
      const dataTransfer = new DataTransfer()
      if (Array.isArray(value)) {
        value.forEach((file) => dataTransfer.items.add(file))
      } else if (value) {
        dataTransfer.items.add(value)
      }
      ref.current.files = dataTransfer.files
    }
  }, [value])

  return (
    <div className="w-full grid gap-2 relative">
      {label && <Label>{label}</Label>}

      <Input
        ref={ref}
        type="file"
        multiple={multiple}
        onChange={handleChange}
        disabled={disabled}
        accept={accept}
        classes={{ input: 'pl-2 pr-5 pt-[6px]' }}
      />

      {hasValue && !disabled && (
        <Button
          variant="ghost"
          className="p-0 h-fit absolute right-1 top-3 z-30 opacity-50 hover:opacity-100"
          onClick={() => {
            if (ref.current) {
              ref.current.value = ''
              onChange(null)
            }
          }}
        >
          <XIcon size={14} />
        </Button>
      )}
    </div>
  )
}
