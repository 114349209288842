'use client'

import React, { useState, useRef, KeyboardEvent, FocusEvent } from 'react'
import { Badge } from './Badge'
import { Input } from './Input'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { XIcon } from 'lucide-react'
import { Button } from './Button'

interface Props {
  value?: string[]
  placeholder?: string
  maxBadges?: number

  onChange?: (value: string[]) => void
}

export const InputTags: React.FC<Props> = ({ value = [], placeholder, maxBadges = 3, onChange }: Props) => {
  const [inputText, setInputText] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useDetectClickOutside({ onTriggered: () => setFocused(false) })

  const removeBadge = (index: number) => {
    onChange?.(value.filter((_, i) => i !== index))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()

      if (inputText.trim()) {
        onChange?.([...value, inputText])
        setInputText('')
      }
    } else if (e.key === 'Backspace' && !inputText) {
      removeBadge(value.length - 1)
    }
  }

  const handleFocus = (e: FocusEvent<HTMLDivElement>) => setFocused(true)

  return (
    <div className="relative w-full min-h-9 cursor-pointer" ref={containerRef}>
      <div
        className="border rounded-md flex flex-wrap gap-2 items-center focus-within:border-black w-full h-full px-2 py-1"
        onFocus={handleFocus}
        tabIndex={0}
      >
        {placeholder && !focused && !value.length && <span className="text-gray-400 text-sm ml-1">{placeholder}</span>}

        {(focused ? value : value.slice(0, maxBadges)).map((badge, index) => (
          <Badge key={index} variant="outline" className="flex gap-1">
            {badge}{' '}
            <Button variant="ghost" className="p-0 h-fit flex" onClick={() => removeBadge(index)} type="button">
              <XIcon size={14} />
            </Button>
          </Badge>
        ))}

        {!focused && value.length > maxBadges && (
          <span className="text-gray-500 text-sm">+{value.length - maxBadges} more</span>
        )}

        {focused && (
          <Input
            ref={inputRef}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type and press Enter..."
            classes={{
              root: 'flex-grow bg-transparent min-w-1/2 h-[24px]',
              input: 'h-full border-none focus-visible:ring-transparent p-1',
            }}
            autoFocus
          />
        )}
      </div>
    </div>
  )
}
