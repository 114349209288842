'use client'

import { XIcon } from 'lucide-react'
import { Button } from './Button'
import { Input } from './Input'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { cn } from '@/lib/utils'
import { StringRange } from '@core/util/types'

interface Props {
  value?: StringRange
  onChange: (value: StringRange) => void
  disabled?: boolean
  placeholder?: string
  classes?: {
    trigger?: string
    content?: string
  }

  onOpenChange?: (isOpen: boolean) => void
}

export const MoneyRange = ({ value, onChange, disabled, placeholder, classes, onOpenChange }: Props) => {
  const from = value?.from ?? ''
  const to = value?.to ?? ''

  const hasRange = from || to

  const handleClearRangeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    onChange?.({})
  }

  return (
    <Popover onOpenChange={onOpenChange} modal>
      <PopoverTrigger asChild>
        <div className={cn('relative overflow-hidden', { 'pointer-events-none': disabled })}>
          {hasRange && (
            <Button
              variant="ghost"
              className="p-0 opacity-50 h-fit hover:opacity-100 absolute right-1 top-3"
              onClick={handleClearRangeClick}
            >
              <XIcon size={14} />
            </Button>
          )}

          <Button
            id="money"
            type="button"
            variant={'outline'}
            className={cn('w-[300px] justify-start text-left font-normal', classes?.trigger)}
            disabled={disabled}
          >
            <span className="overflow-hidden text-ellipsis">
              {hasRange ? (
                `${from || '_'} - ${to || '_'}`
              ) : (
                <span className={cn('text-muted-foreground', 'text-slate-400')}>{placeholder || 'Set $ range'}</span>
              )}
            </span>
          </Button>
        </div>
      </PopoverTrigger>

      <PopoverContent className="flex gap-4">
        <Input
          label="From"
          format="money"
          value={from}
          placeholder="-$123.45"
          onChange={(e) => onChange({ from: e.target.value, to: value?.to })}
        />
        <Input
          label="To"
          format="money"
          value={to}
          placeholder="$123.45"
          onChange={(e) => onChange({ from: value?.from, to: e.target.value })}
        />
      </PopoverContent>
    </Popover>
  )
}
